import React, {Component} from 'react';
import svgPhoto from './error.svg';
import GppBadIcon from '@mui/icons-material/GppBad';
import {SyncOutlined,
} from '@ant-design/icons';
class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
      this.state = { hasError: false, error: null, errorInfo: null };
      // console.log(process.env.REACT_APP_ENV)
  }

    
    
  componentDidCatch(error, errorInfo) {
    this.setState({hasError: true, error, errorInfo});
    // You can log the error to an error tracking service here
    // For example: logErrorToMyService(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can customize the error display here
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#ff00003b',
            height: '125vh',
            flexDirection: 'column',
          }}>
          <div
            style={{
              // width:"50px"
              display: 'flex',
              alignItems: 'center',
                      justifyContent: 'center',
              flexDirection:"column"
            }}>
            <GppBadIcon style={{width: '150px', height: '150px'}} />
                  {/* <img style={{width: '20%'}} src={svgPhoto}></img> */}
                  <span className='fade-in-out' >
                      CRASH DETECTED
                      </span>
          </div>
          <div style={{margin: '50px'}}>
                 
                     
            <p>{this.state.error && this.state.error.toString()}<span className='fade-in-out-speed' >|</span></p>
            {process.env.REACT_APP_ENV == 'Dev' && (
              <div>
                <p>Component Stack Trace:</p>
                <p>
                              {this.state.errorInfo
                                &&  this.state.errorInfo.componentStack
                              }
                </p>
              </div>
            )}
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;

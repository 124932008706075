import React from 'react';
import LoadingOverlay from 'react-loading-overlay';
import {  BeatLoader} from 'react-spinners';


export default function MySpinner({active, children}) {
  return (
    <div style={{}}>
<LoadingOverlay
      active={active}
      spinner={<BeatLoader size={50} color='black'  speedMultiplier={1}/>}
        styles={{
          wrapper: {
          opacity:0.7,
         
          // width: active ? '100%' : 'auto',
          // height: active ? '100%' : 'auto',
          
          top: "15%",
          right:0,
          position: 'absolute',
        
          'z-index': 20000,
          left: 0,
          
        },
      }}
    ></LoadingOverlay>
{/* <LoadingOverlay
      active={active}
      spinner={<BounceLoader />}
      styles={{
        wrapper: {
          // width: active ? '100%' : 'auto',
          // height: active ? '100%' : 'auto',
          top: "50%",
          right:150,
          position: 'absolute',
        
          'z-index': 20000,
          left: 0,
          
        },
      }}
    ></LoadingOverlay>
<LoadingOverlay
      active={active}
      spinner={<BounceLoader />}
      styles={{
        wrapper: {
          // width: active ? '100%' : 'auto',
          // height: active ? '100%' : 'auto',
          top: "50%",
          right:300,
          position: 'absolute',
        
          'z-index': 20000,
          left: 0,
          
        },
      }}
    ></LoadingOverlay> */}

    </div>
  );
}

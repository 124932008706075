import React, {useEffect, useContext} from 'react';
import history from 'services/history';
import {Router, Switch, Route} from 'react-router-dom';
import {AuthContext} from 'services';
import LogIn from 'pages/login';
import Main from 'pages/Main';
import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-calendar/dist/Calendar.css';
// import ErrorBoundary from 'antd/es/alert/ErrorBoundary';
import ErrorBoundary from 'errohandler';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
import 'react-phone-input-2/lib/style.css'
// const {REACT_APP_CENTERFORM_API} = process.env;

const App = () => {
  const targetContext = useContext(AuthContext);
  const {loggedIn, user_data} = targetContext.state;

  const {getLoginState} = targetContext;

  useEffect(() => {
    document.body.style.zoom = '.8';
    getLoginState();
  }, []);
// // console.log(loggedIn);
  return (
    <ErrorBoundary>
      <div style={{height: '125vh', overflowY: 'hidden'}}>
        <Router history={history}>
          <Switch>
            {loggedIn && user_data ? <Main /> : null}
            {<Route
              exact
              path="/login"
              render={props =>
                !loggedIn && !user_data ? (
                  <LogIn {...props} />
                ) : (
                  <Redirect
                    to={
                      user_data?.type != 'admin'
                      ?`/collaborateurs/${user_data?._id}`
                        : '/standardUsers'
                    }
                  />
                )
              }
            />}
            {/* <Route
              exact
              path="/"
              render={() => (
                <Redirect
                  to={
                    user_data.type == 'admin'
                      ? '/standardUsers'
                      : `/collaborateurs/${user_data._id}`
                  }
                />
              )}
            /> */}
          </Switch>
          {/* <AppFooter /> */}
        </Router>
      </div>
    </ErrorBoundary>
  );
};

export default App;

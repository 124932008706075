import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from 'react-router-dom';
import React, {Suspense, lazy, Fragment, useContext, useEffect} from 'react';
import {MySpinner} from 'components/atoms';
import {ToastContainer} from 'react-toastify';
import {AuthContext} from 'services';
import codePromoScreen from 'pages/codePromo';
import CguPage from 'pages/Cgu';
import ChatPage from 'pages/Chats';
// import NotFound from '404';
const Collaborators = lazy(() => import('pages/Collaborators/'));
const pubs = lazy(() => import('pages/Collaborators/indexPub'));
const CreateCollaborator = lazy(() =>
  import('pages/Collaborators/NewCollaborator'),
);
const ViewCollaborator = lazy(() =>
  import('pages/Collaborators/ViewCollaborator'),
);
const EditCollaborator = lazy(() =>
  import('pages/Collaborators/EditCollaborator'),
);
const CreateAdmin = lazy(() => import('pages/Collaborators/myProfile'));
const StandardUsers = lazy(() => import('pages/StandardUsers/'));
const CollaboratorClients = lazy(() => import('pages/CollaboratorClients/'));
const Meals = lazy(() => import('pages/Meals/'));
const Exempls = lazy(() => import('pages/exempls/index.js'));
const ExemplsCreate = lazy(() => import('pages/exempls/create.js'));
const ShowAndEditMeal = lazy(() => import('pages/exempls/showAndEdit.js'));
const DemandesPage = lazy(() => import('pages/demandes'));
const CreateMeal = lazy(() => import('pages/Meals/CreateMeal'));
const EditMeal = lazy(() => import('pages/Meals/EditMeal'));
const UserProfile = lazy(() => import('pages/UserProfile/'));
const PhysicalUsers = lazy(() => import('pages/PhysicalUsers/'));
const PremiumUsers = lazy(() => import('pages/PremiumUsers/'));
const Diagnosis = lazy(() => import('pages/User/Diagnosis'));
const CreateUser = lazy(() => import('pages/User/NewUser'));
const EditUser = lazy(() => import('pages/User/EditUser'));
const CalendarView = lazy(() => import('pages/Calendar'));
const Fruits_Vegtables = lazy(() => import('pages/Meals/Fruits_Vegtables'));
const PricesPlans = lazy(() => import('pages/Plans'));
const notFound = lazy(() => import('./../../404'));

const ProductsScreen = lazy(() => import('pages/Products'));
const AppMain = () => {
  // useEffect(() => {
  //   // document.body.style.zoom="90%"
  //   // getLoginState();
  // }, []);

  const CenterFormContext = useContext(AuthContext);
  const {user_data} = CenterFormContext.state;
  if (user_data.type == 'admin') {
    return (
      <Fragment>
        {/* Components */}
        <Suspense
          fallback={
            <div className="loader-container">
              <div className="loader-container-inner">
                <MySpinner active={true} />
              </div>
            </div>
          }>
          <Switch>
            <Route
              path="/collaborateurs/clients/:id"
              component={CollaboratorClients}
            />

            <Route exact path="/standardUsers" component={StandardUsers} />
            <Route
              exact
              path="/standardUsers/create"
              render={props => <CreateUser {...props} type={'standard'} />}
            />

            <Route exact path="/standardUsers/:id" component={UserProfile} />
            <Route
              exact
              path="/standardUsers/:id/diagnostic"
              render={props => <Diagnosis {...props} type={'standard'} />}
            />
            <Route
              exact
              path="/standardUsers/:id/edit"
              render={props => <EditUser {...props} type={'standard'} />}
            />

            <Route exact path="/calendar/" component={CalendarView} />
            {/* <Route  path="*" render={() => (<div>404 page</div>)} />   */}

            <Route exact path="/products" component={ProductsScreen} />
            {/* <Route  path="*" render={() => (<div>404 page</div>)} />   */}

            <Route exact path="/prices-plan" component={PricesPlans} />
            {/* <Route  path="*" render={() => (<div>404 page</div>)} />   */}

            <Route exact path="/promo-code" component={codePromoScreen} />

            <Route exact path="/physicalUsers" component={PhysicalUsers} />
            <Route
              path="/physicalUsers/create"
              render={props => <CreateUser {...props} type={'physical'} />}
            />
            <Route exact path="/physicalUsers/:id" component={UserProfile} />
            <Route
              exact
              path="/physicalUsers/:id/diagnostic"
              render={props => <Diagnosis {...props} type={'physical'} />}
            />
            <Route
              exact
              path="/physicalUsers/:id/edit"
              render={props => <EditUser {...props} type={'physical'} />}
            />

            <Route exact path="/premiumUsers" component={PremiumUsers} />
            <Route
              path="/premiumUsers/create/:colId"
              render={props => <CreateUser {...props} type={'premium'} />}
            />

            <Route
              path="/premiumUsers/create"
              render={props => <CreateUser {...props} type={'premium'} />}
            />
            <Route exact path="/premiumUsers/:id" component={UserProfile} />
            <Route
              exact
              path="/premiumUsers/:id/diagnostic"
              render={props => <Diagnosis {...props} type={'premium'} />}
            />
            <Route
              exact
              path="/premiumUsers/:id/edit"
              render={props => <EditUser {...props} type={'premium'} />}
            />

            {/* <Route exact path="/profile" component={Collaborators} /> */}
            <Route exact path="/collaborateurs" component={Collaborators} />
            <Route exact path="/pubs" component={pubs} />
            <Route exact path="/mes-demandes" component={DemandesPage} />
            <Route exact path="/cgu" component={CguPage} />
            <Route exact path="/messagerie" component={ChatPage} />

            <Route
              exact
              path="/collaborateurs/create"
              component={CreateCollaborator}
            />
            <Route exact path="/administrateurs" component={CreateAdmin} />
            <Route
              exact
              path="/collaborateurs/:id"
              component={ViewCollaborator}
            />
            <Route
              exact
              path="/collaborateurs/:id/edit"
              component={EditCollaborator}
            />

            <Route
              exact
              path="/meals-standard"
              render={props => <Meals {...props} type={'standard'} />}
            />
            <Route exact path="/exemples" component={Exempls} />
            <Route exact path="/exemples/create" component={ExemplsCreate} />
            <Route exact path="/exemples/:id"  component={ShowAndEditMeal}/>
            <Route
              exact
              path="/meals-premium"
              render={props => <Meals {...props} type={'premium'} />}
            />

            <Route
              path="/meals-standard/fruits-vegtables"
              component={Fruits_Vegtables}
            />
            <Route
              exact
              path="/meals-standard/createMeal"
              render={props => <CreateMeal {...props} type={'standard'} />}
            />
            <Route
              exact
              path="/meals-premium/createMeal"
              render={props => <CreateMeal {...props} type={'premium'} />}
            />

            <Route
              path="/meals-standard/:id"
              render={props => <EditMeal {...props} type={'standard'} />}
            />
            <Route
              exact
              path="/meals-premium/:id"
              render={props => <EditMeal {...props} type={'premium'} />}
            />

            <Route
              exact
              path="/"
              render={() => (
                <Redirect
                  to={
                    user_data.type == 'admin'
                      ? '/standardUsers'
                      : `/collaborateurs/${user_data._id}`
                  }
                />
              )}
            />
            <Route path="*" component={notFound} />
          </Switch>
        </Suspense>
        <ToastContainer />
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        {/* Components */}

        <Suspense
          fallback={
            <div className="loader-container">
              <div className="loader-container-inner">
                <MySpinner active={true} />
              </div>
            </div>
          }>
          <Switch>
            <Route
              path="/collaborateurs/clients/:id"
              component={CollaboratorClients}
            />

            {/* <Route exact path="/standardUsers" component={StandardUsers} />
            <Route
              exact
              path="/standardUsers/create"
              render={props => <CreateUser {...props} type={'standard'} />}
            /> */}

            {/* <Route exact path="/standardUsers/:id" component={UserProfile} /> */}
            {/* <Route
              exact
              path="/standardUsers/:id/diagnostic"
              render={props => <Diagnosis {...props} type={'standard'} />}
            /> */}
            {/* <Route
              exact
              path="/standardUsers/:id/edit"
              render={props => <EditUser {...props} type={'standard'} />}
            /> */}

            <Route exact path="/calendar/" component={CalendarView} />

            <Route exact path="/products" component={ProductsScreen} />

            {/* <Route exact path="/prices-plan" component={PricesPlans} /> */}

            {/* <Route exact path="/promo-code" component={codePromoScreen} /> */}

            <Route exact path="/physicalUsers" component={PhysicalUsers} />
            <Route
              path="/physicalUsers/create"
              render={props => <CreateUser {...props} type={'physical'} />}
            />
            <Route exact path="/physicalUsers/:id" component={UserProfile} />
            <Route
              exact
              path="/physicalUsers/:id/diagnostic"
              render={props => <Diagnosis {...props} type={'physical'} />}
            />
            <Route
              exact
              path="/physicalUsers/:id/edit"
              render={props => <EditUser {...props} type={'physical'} />}
            />

            {/* <Route exact path="/premiumUsers" component={PremiumUsers} /> */}
            {/* <Route
              path="/premiumUsers/create/:colId"
              render={props => <CreateUser {...props} type={'premium'} />}
            /> */}

            {/* <Route
              path="/premiumUsers/create"
              render={props => <CreateUser {...props} type={'premium'} />}
            /> */}
            {/* <Route exact path="/premiumUsers/:id" component={UserProfile} /> */}
            {/* <Route
              exact
              path="/premiumUsers/:id/diagnostic"
              render={props => <Diagnosis {...props} type={'premium'} />}
            /> */}
            {/* <Route
              exact
              path="/premiumUsers/:id/edit"
              render={props => <EditUser {...props} type={'premium'} />}
            /> */}

            {/* <Route exact path="/profile" component={Collaborators} /> */}
            {/* <Route exact path="/collaborateurs" component={Collaborators} /> */}
            <Route exact path="/mes-demandes" component={DemandesPage} />
            {/* <Route exact path="/cgu" component={CguPage} /> */}
            <Route exact path="/messagerie" component={ChatPage} />

            {/* <Route
              exact
              path="/collaborateurs/create"
              component={CreateCollaborator}
            /> */}
            <Route
              exact
              path="/collaborateurs/:id"
              render={p => <ViewCollaborator who={user_data._id} />}
            />
            <Route
              exact
              path="/collaborateurs/:id/edit"
              component={EditCollaborator}
            />

            <Route
              exact
              path="/meals-standard"
              render={props => <Meals {...props} type={'standard'} />}
            />

            <Route exact path="/exemples" component={Exempls} />
            <Route exact path="/exemples/create" component={ExemplsCreate} />
            <Route exact path="/exemples/:id"  component={ShowAndEditMeal}/>

            {/* <Route exact path="/exemples/:id" component={ShowAndEditMeal} /> */}
            <Route
              exact
              path="/meals-premium"
              render={props => <Meals {...props} type={'premium'} />}
            />

            <Route
              path="/meals-standard/fruits-vegtables"
              component={Fruits_Vegtables}
            />
            <Route
              exact
              path="/meals-standard/createMeal"
              render={props => <CreateMeal {...props} type={'standard'} />}
            />
            <Route
              exact
              path="/meals-premium/createMeal"
              render={props => <CreateMeal {...props} type={'premium'} />}
            />

            <Route
              path="/meals-standard/:id"
              render={props => <EditMeal {...props} type={'standard'} />}
            />
            <Route
              exact
              path="/meals-premium/:id"
              render={props => <EditMeal {...props} type={'premium'} />}
            />

            <Route
              exact
              path="/"
              render={() => (
                <Redirect
                  to={
                    user_data.type == 'admin'
                      ? '/standardUsers'
                      : `/collaborateurs/${user_data._id}`
                  }
                />
              )}
            />
            <Route path="*" component={notFound} />

            <ToastContainer />
          </Switch>
        </Suspense>
      </Fragment>
    );
  }
};

export default AppMain;

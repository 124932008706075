import React, {useContext, useState, Fragment} from 'react';
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import './index.css';
import {AuthContext} from 'services';
const {REACT_APP_CENTERFORM_API} = process.env;
import {
  Card,
  CardBody,
  Container,
  CardTitle,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Button,
  Spinner,
} from 'reactstrap';
import centerFormLogo from 'assets/images/logo.png';
import centerFormLogo2 from 'assets/images/logo2.png';
import {useHistory} from 'react-router-dom';

// import history from 'services/history';
import {border} from '@material-ui/system';
import AppFooter from 'Layout/AppFooter';
const LogIn = () => {
  const history = useHistory();
  const targetContext = useContext(AuthContext);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [invalid, setInvalid] = useState(false);
  const [loading, setLoading] = useState(false);
  const login = async e => {
    e.preventDefault();
    const {signIn, getLoginState} = targetContext;
    setLoading(true);
    const res = await signIn({email, password});
    setInvalid(!res);
    setLoading(false);
    if (res) {
      const user = await getLoginState();
      if (user.type === 'admin') {
        // return history.push('/standardUsers');
        return history.push('/collaborateurs');
      } else {
        return history.push(`/collaborateurs/${user._id}`);
      }
    }
  };
  // // console.log(REACT_APP_CENTERFORM_API);
  return (
    <Fragment>
      <CSSTransitionGroup
        transitionName="example"
        transitionEnterTimeout={500}
        transitionLeaveTimeout={300}>
        <div
          className="loginContainer"
          style={{overflowY:"none", background: '#fff8e1'}}>
          {/* <div className={process.env.REACT_APP_ENV!="sandbox"?"envlg":"envSBlg"}> {process.env.REACT_APP_ENV=="sandbox"?process.env.REACT_APP_ENV:process.env.REACT_APP_ENV+"eloper"} mode </div> */}

          <Container style={{width: '500px', marginBottom: '25px'}}>
            <div
              style={{
                display: 'flex',
                borderBottom: '2px solid white',
                justifyContent: 'center',
                marginBottom: '25px',
                flexDirection: 'column',
              }}>
              <img
                src={centerFormLogo}
                alt="logo"
                style={{border: '1px solid white', marginBottom: '0px'}}
              />

              <img
                src={centerFormLogo2}
                alt="logo"
                style={{
                  width: '80%',
                  height: '50px',
                  border: '1px solid white',
                  marginBottom: '0px',
                  marginTop: '1px',
                }}
              />
            </div>
            <Card
              className="main-card mb-3"
              style={{
                border: '1px solid gray',
                marginTop: 'px',
                background: '#000000',
                color: 'white',
              }}>
              <CardBody>
                {/* <div
                  className={
                    process.env.REACT_APP_ENV != 'sandbox' ? 'envlg' : 'envSBlg'
                  }>
                  {' '}
                Bienvenue
                </div> */}

                <CardTitle style={{color: 'white'}}>Connexion</CardTitle>
                <Form onSubmit={login}>
                  <FormGroup>
                    <Label>Email</Label>
                    <Input
                      title="Tapez votre Email ici"
                      value={email}
                      onChange={e => setEmail(e.target.value)}
                      type="email"
                      placeholder="Ex: Admin@email.com"
                      invalid={invalid}
                      required
                      onInput={e => e.target.setCustomValidity('')} // Reset the custom validity on input
                      onInvalid={e => {
                        e.target.setCustomValidity('Ce champ est obligatoire!');
                      }} // Set the custom validation message
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>Mot de passe</Label>
                    <Input
                      title="Tapez votre Mot de passe ici"
                      value={password}
                      onChange={e => setPassword(e.target.value)}
                      type="password"
                      placeholder="Mot de passe  "
                      invalid={invalid}
                      required
                      onInput={e => e.target.setCustomValidity('')} // Reset the custom validity on input
                      onInvalid={e => {
                        e.target.setCustomValidity('Ce champ est obligatoire!');
                      }} // Set the custom validation message
                    />
                    <FormFeedback>
                      L'e-mail ou le mot de passe ne sont pas valides, réessayez
                    </FormFeedback>
                  </FormGroup>
                  <Button
                    disabled={loading}
                    block
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '100%',
                      height: '60px',
                    }}
                    className="mb-2 mt-4 mr-2 submitButton"
                    size="lg">
                    {loading ? (
                      <span
                        style={{
                          marginRight: '.5rem',
                          paddingBottom: '3px',
                          width: '30px',
                        }}>
                        <Spinner
                          style={{width: '25px', height: '25px'}}
                          as="span"
                          variant="warning"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          animation="grow"
                        />{' '}
                      </span>
                    ) : (
                      <p style={{marginBottom: '1px'}}>Se connecter</p>
                    )}
                  </Button>
                </Form>
              </CardBody>
            </Card>
          </Container>
        </div>
      </CSSTransitionGroup>
      <AppFooter />
    </Fragment>
  );
};

export default LogIn;

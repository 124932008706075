import moment from 'moment';
import {
  checkSuccessive,
  expandArray,
  findMax,
  findMin,
  generateConditions,
  generateData,
  generateIntervals,
  generateIntervalsAll,
  generateIntervalsOW,
  generateIntervalsW,
  userWeights,
} from './extra';

export const optionW = ({
  type,
  weeks,
  weights,
  label,
  maxMin,
  area,
  weeksNumber,
  colorWeight,
  objWeights,
  objWeightsColor,
  checkerFormOrObj,
  // expandArrayOb
}) => {
  // console.log(objWeightsColor);
  // console.log(weeks);
  // // console.log("gen",[...weights]);
  // // console.log("gen",generateIntervalsAll(colorWeight,false,"red","black"));
  // // let filltredAppliedChecker=[]
  // // // console.log(appliedChecker);
  // if (objWeights.length > weights.length) {
  //   filltredAppliedChecker=objWeights.slice(0,weights.length)
  // } else {
  //   filltredAppliedChecker=[...appliedChecker]
  // }
  // // console.log(appliedCheckerColor);
  // const weightDataObjNew = weightDataObj.map(d => d.weight)

  // // console.log(expandArray(weightDataObjNew,checkSuccessive(weightDataObjNew)));
  // const weightsArray = userWeights.map(weight => weight.weight);
  // // console.log(generateConditions(userWeights));
  // let lisOfobjectiveWeight = weights.map(data => objectiveWeight);

  // // console.log(weeks.map(d => `${d.split('-')[1]}-${d.split('-')[2]}`));

  // // console.log(expandArrayOb);

  // lisOfobjectiveWeight[3]=60
  // lisOfobjectiveWeight[4]=50
  // lisOfobjectiveWeight[5]=50

  // // console.log("sdsd",generateIntervalsOW(appliedCheckerColor));
  // // console.log('sdsd ', appliedChecker);
  // // console.log('sdsd ', weights);
  // if (appliedChecker.length < weights.length) {
  //   for (let i = 0; i < weights.length-appliedChecker.length ; i++){
  //     appliedChecker.push(appliedChecker[appliedChecker.length-1])
  //   }

// // console.log("lll",checkerFormOrObj);
  
  // }
  const filterweights = weights.filter(d => d != undefined)
  if (filterweights.length == 0) {
    weeks=[]
  }
  return {
    title: {
      left: 'center',
      text: filterweights.length==0?"Pas encore de données !":type=="premium"? "Poids historisé : Prestation physique":"Poids historisé",
      // subtext: 'Fake Data'
    },
    toolbox: {
      feature: {
        // saveAsImage: {
        //   iconStyle: {
        //     color: 'orange', // Custom text color (red in this example)
        //   },
        //   title: "Enregistrer en tant qu'image",
        // }, // Enable the saveAsImage feature
      },
    },
    grid: {
      // left: '10%',   // Adjust the left padding
      // right: '10%',  // Adjust the right padding
      top: '10%', // Adjust the top padding
      bottom: '21%', // Adjust the bottom padding
    },

    tooltip: {
    // grid: {
    //   top: '8%',
    //   bottom: '100',
    // },
      // trigger: 'axis',
      // axisPointer: {
      //   type: 'cross',
      // }
    },
    // legend: {
    //   // orient: 'Horizontal',
    //   // right: 5,
    //   // top: 'left',

    // },
    xAxis: {
      name: 'Semaines',
      // max: weights.length,
      type: 'category',
      boundaryGap: false,
      // boundaryGap: [1, '10%'],
      // prettier-ignore
      data: weeks
        .map((d, i) => `S${moment(d).week()} 
${d.split('-')[2]}/${d.split('-')[1]}/${d.split('-')[0]}`),
    },
   
    // yAxis:
    yAxis: 
      [{
        name: 'Poids en Kg',
        type: "value",
        min: findMin([...filterweights,...objWeights])-15,
        max:findMax([...filterweights,...objWeights])+15 ,
          interval: 5,
          axisLabel: {
                  formatter: '{value}    ',
                },
        
        }]
      
  //   {
  //     name: 'Poids en Kg',
  //     // type: 'value',
  //     // type: 'category',
  //     // min: 40, // Set the minimum value
  //     // max: weights.length == 0 ? 100 : findMax([...weights,...appliedChecker]) , // Set the maximum value
  //     // // max: weights.length == 0 ? 100 : weights.length, // Set the.length maximum value
  //     // data: [20, 25, 30, 35, 40, 45, 60, 75, 80, 85, 90, 95, 100, 105,110],
      
  //       // Set the maximum value
  //     ,
  //     axisLabel: {
  //       formatter: '{value}    ',
  //     },
  //     axisPointer: {
  //       snap: true,
  //     },
  // }
    ,

    dataZoom: [
      {
        show: true,
        realtime: true,
        // start: 1,
        // end: weeks.length + 100,
        backgroundColor: '#FEE8B0',
      },
      {
        type: 'inside',
        realtime: true,
        // start: 1,
        // end: weeks.length + 1,
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
        yAxisIndex: 0

      },
    ],

    visualMap: [
      {
        show: false,
        dimension: 0,
        seriesIndex: 1,
        pieces:
          // [
          // { lt: 2, color: '#03AED2' }, { gt: 2, lt: 3, color: '#ffa500' }
        
          // {
          //     "lt": 1,
          //     "color": "#ffa500"
          // },
          // {
          //     "gt": 1,
          //     "lt": 2,
          //     "color": "#03AED2"
          // },
          // {
          //     "lt": 3,
          //     "color": "#ffa500",
          //     "gt": 4
          // }
      // ]
          
          generateIntervalsAll(colorWeight, false, "#ffa500",  "#03AED2"),
      },
      {
        show: false,
        dimension: 0,
        seriesIndex: 0,
        pieces:
          generateIntervalsAll(objWeightsColor, checkerFormOrObj == "losing weight" ? true : false, "#640D6B", "green"),
      },
    ],

    series: [
      {
        name: 'objectif/forme',
        type: 'line',
        stack: 'Total',
        // smooth: true,
        data: objWeights,
        label: label
          ? {
              show: true,
              backgroundColor: '#eee',
              borderColor: '#333',
              borderWidth: 1,
              padding: 5,
              // left:10,
              position: 'insideTopLeft',
              distance:10,
              borderRadius: 5,
              shadow: {
                backgroundColor: '#992233',
                padding: 5,
                color: '#fff',
                shadowBlur: 5,
                shadowColor: '#336699',
                shadowOffsetX: 6,
                shadowOffsetY: 6,
              },
            }
          : {},
      },
      {
        markPoint: maxMin
          ? {
              data: [
                {
                  type: 'max',
                  name: 'Max',
                  itemStyle: {
                    color: 'red', // Set the color for the mark points
                  },
                },
                {
                  type: 'min',
                  name: 'Min',
                  itemStyle: {
                    color: 'green', // Set the color for the mark points
                  },
                },
              ],
            }
          : {},
        name: 'Poids',
        type: 'line',

        // smooth: 1,
        label: label
          ? {
              show: true,
              backgroundColor: '#eee',
              borderColor: '#333',
              borderWidth: 1,
              padding: 5,
              // left:10,
              position: 'insideTopRight',
              distance: -25,
              borderRadius: 5,
              shadow: {
                backgroundColor: '#992233',
                padding: 5,
                color: '#fff',
                shadowBlur: 5,
                shadowColor: '#336699',
                shadowOffsetX: 6,
                shadowOffsetY: 6,
              },
            }
          : {},
        // lineStyle: {
        //   color: 'orange',
        //   // width: 4,
        // },
        areaStyle: !area
          ? {
              color: '#FFB84C',
              opacity: 0.1,
            }
          : {},
        data: filterweights.length == 0 ? [] : filterweights,
      },
    ],
  };
};

import React from 'react';

function CustomCalendarHeader({date, decreaseMonth, increaseMonth}) {
  return (
    <div className="custom-calendar-header">
      <button onClick={decreaseMonth}>Previous</button>
      <div>{date.toLocaleString('default', {month: 'long'})}</div>
      <button onClick={increaseMonth}>Next</button>
    </div>
  );
}

export default CustomCalendarHeader;

import 'react-datepicker/dist/react-datepicker.css';

export function CustomDatePickerInput({customValidityMessage,value, ...props}) {
  return (
    <>
      {props.from == 'app' ? (
        <label style={{marginBottom: '51px'}} for="">
          Date {value}
        </label>
      ) : (
        ''
      )}
      <input
        value={value}
        style={{
          border: '1px solid #d3d8dd',
          width: '100%',
          marginTop: '5px',
          height: '44.5px',
          borderRadius: '6px',
        }}
        title="date"
        name=""
        onChange={(e) => {
        // // console.log("fffffffffffffffffffffff",e);
          e.target.setCustomValidity('')
        
        }}
        // onInput={(e) => e.target.setCustomValidity('')}
        onInvalid={e => {
            e.target.setCustomValidity('');

          // console.log(!e.target.value), value;
          if (!e.target.value) {
             e.target.setCustomValidity('Ce champ est obligatoire!');
          } else if (e.target.value) {
            // e.preventDefault();
          }
        }}
        {...props}
      />
    </>
  );
}

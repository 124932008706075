import React, {Fragment, useState, useContext, useRef, useEffect} from 'react';
import {Button, Flex, notification} from 'antd';
import {
  DropdownToggle,
  DropdownMenu,
  Nav,
  // Button,
  NavItem,
  NavLink,
  UncontrolledTooltip,
  UncontrolledButtonDropdown,
  ModalBody,
  Modal,
  ModalHeader,
  ModalFooter,
} from 'reactstrap';
import iconNotification from 'shared/bell.png';

import {AuthContext} from 'services';
import SearchBox from './SearchBox';
import NotificationsIcon from '@mui/icons-material/Notifications';
// import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import notifPng from '../../../shared/notif.png';
import {DesktopOutlined, LoadingOutlined} from '@ant-design/icons';
import ButtonAntDesign from 'components/atoms/AntdButton';
import CenterFormApi from 'services/api/CenterFormApi';
import {gotToLink, tagoToMessage, titleToMessage} from 'shared/extra';
import history from 'services/history';
import Pusher from 'pusher-js';
import newMsgsoundUrl from '../../../shared/cute_sound.mp3';

import {Howl} from 'howler';

const UserBox = () => {
  const [showNotifePage, setShowNotifePage] = useState(false);
  const [showNotifModal, setShowNotifModal] = useState(false);
  const [loaderNotif, setLoaderNotif] = useState(false);

  const [notificationsListe, setnotificationsListe] = useState([]);
  const [loaderMore, setloaderMore] = useState(false);

  const [showNotifeNumber, setShowNotifeNumber] = useState(false);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const CenterFormContext = useContext(AuthContext);
  const {user_data} = CenterFormContext.state;
  const {signOut} = CenterFormContext;
  const notificationRef = useRef(null);


  
  const newMsgsound = new Howl({
    src: [newMsgsoundUrl],
  });

  useEffect(() => {
    const PusherApp = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
      cluster: process.env.REACT_APP_PUSHER_CLUSTER,
      encrypted: true,
      secret: process.env.REACT_APP_PUSHER_SECRET,
      appId: process.env.REACT_APP_PUSHER_APPID,
    });

    const channel = PusherApp.subscribe(user_data.type == 'admin'?'Super':user_data._id.toString());
    channel.bind('new notification', function (data) {
      // // console.log(data);
      setShowNotifeNumber(true)
      newMsgsound.play();
      // Update your component state or perform actions based on the received data
    });

    return () => {
      PusherApp.unsubscribe(user_data.type == 'admin'?'Super':user_data._id);
      PusherApp.disconnect();
    };
  }, []);



  const fetchNotifications = async (newOpen) => {
    try {
      setLoaderNotif(true)
      setloaderMore(true)
      const res = await CenterFormApi.post(
        '/api/back-office/fetch-notifications',
        {
          collaborator_id: user_data.type != 'admin' ? user_data._id : null,
          super_admin: user_data.type != 'admin' ? false : true,
          page:newOpen?1: page,
          limit: page==1?20:10,
        },
   
      )
      setLoaderNotif(false)
      setnotificationsListe(res.data.data);
      // setnotificationsListe([]);
      setTotal(res.data.total);
      // console.log('res :', notificationsListe.length);
      setloaderMore(false)
    } catch (e) {
      setLoaderNotif(false)
      setloaderMore(false)
      
    }
    // setnotificationsListe(res.data.list);
  };

  const updateSeen =async (idNotif,data) => {
    try {
      // console.log(idNotif);
    setPage(page + 1);
    const res = await CenterFormApi.put(
     'api/backoffice/update-notifications-seen',
      {
        "notification_id":idNotif
      },
      );
      if (res.data.statusCode == 200) {
        document.location.href=gotToLink(data.payload.tag,data.payload.user_id);
      }
    } catch (e) {
      
    }
}


  const moreNotifications = async () => {
    setloaderMore(true)
    setPage(page + 1);
    const res = await CenterFormApi.post(
      '/api/back-office/fetch-notifications',
      {
        collaborator_id: user_data.type != 'admin' ? user_data._id : null,
        super_admin: user_data.type != 'admin' ? false : true,
        page: page + 1,
        limit: 10,
      },
    );
    setnotificationsListe(prev => [...prev, ...res.data.data]);
    setTotal(res.data.total);
    setloaderMore(false)
    // // console.log('res :', notificationsListe.length);
    // setnotificationsListe(res.data.list);
  };

  const handleOutsideClick = event => {
    if (
      notificationRef.current &&
      !notificationRef.current.contains(event.target)
    ) {
      if (event.target instanceof SVGPathElement) {
        // console.log('Clicked on a path inside SVG');
      } else {
        setShowNotifePage(false);
      }
    }
  };
  const handleInsideClick = event => {
    if (
      notificationRef.current &&
      !notificationRef.current.contains(event.target)
    ) {
      // setShowNotifePage(true);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    document.addEventListener('mouseup', handleInsideClick);
    return () => {
      document.removeEventListener('mousedup', handleInsideClick);
    };
  }, []);

  // console.log(notificationsListe);
  return (
    <Fragment>
      <div className="header-btn-lg pr-0">
        <div className="widget-content p-0">
          <div className="widget-content-wrapper">
            <div className="widget-content-left">
              <div className="user-left-container">
                {/* <SearchBox /> */}
                {/* <span className="icon-notifications" /> */}
                <div className="header-btn-lg pe-0">
                  <div className="widget-content p-0">
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left">
                        <UncontrolledButtonDropdown>
                          <DropdownToggle
                            color="gray"
                            // color="link" className="p-0"
                          >
                            <div
                              style={{
                                color: 'black',

                                borderLeft: '1px solid black',
                              }}
                              className="user-container">
                              <svg
                                style={{marginRight: '5px'}}
                                id="i-chevron-bottom"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 32 32"
                                width="20"
                                height="20"
                                fill="none"
                                stroke="black"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2">
                                <path d="M30 12 L16 24 2 12" />
                              </svg>
                              <p>
                                {user_data?.firstName} {user_data?.lastName}
                              </p>
                            </div>
                          </DropdownToggle>

                          <DropdownMenu
                            // end
                            style={{position: 'static !important;'}}
                            // className="rm-pointers"
                          >
                            <Nav vertical>
                              <NavItem className="nav-item-header">
                                Compte 
                              </NavItem>

                             {user_data.type=='admin'&&<NavItem className="center-nav-item">
                              <ButtonAntDesign
                                  color={'orange'}
                                  text={'Mes configurations'}
                                  type="primary"
                                  style={{
                                    // marginRight: "50px",
                                    margin: ' 2px auto',
                                    color: 'black',
                                    minWidth: '90%',
                                    border: '1px solid black',
                                    marginLeft: 'auto',
                                    display: 'block',
                                  }}
                              
                                  onClick={()=>{history.push('/administrateurs')}}
                                />
                              </NavItem>}

                              <NavItem className="center-nav-item">
                                <ButtonAntDesign
                                  color={'orange'}
                                  text={' Se déconnecter'}
                                  type="primary"
                                  style={{
                                    // marginRight: "50px",
                                    margin: ' 2px auto',
                                    color: 'black',
                                    minWidth: '90%',
                                    border: '1px solid black',
                                    marginLeft: 'auto',
                                    display: 'block',
                                  }}
                              
                                  onClick={signOut}
                                /></NavItem>
                                
                                <NavItem className="center-nav-item">
                               
                              </NavItem>
                            </Nav>
                          </DropdownMenu>
                        </UncontrolledButtonDropdown>
                      </div>

                      <NotificationsIcon
                        id="notifBtn"
                        onClick={e => {
                          setShowNotifeNumber(false)
                          !showNotifePage&&fetchNotifications(true);
                          setShowNotifePage(!showNotifePage);
                          setPage(1)
                        }}
                        style={{color: 'black'}}
                      />

                      {/* <DesktopOutlined width={20} style={{ position: "relative" }} />  */}
                      {showNotifeNumber && (
                        <div
                          // onClick={e=>setShowNotifePage(!showNotifePage)}
                          style={{
                            margin: 0,
                            padding: 0,
                            textAlign: 'center',
                            // border: '1px solid red',
                            top: 5,
                            right: -8,
                            fontSize: '10px',
                            position: 'absolute',
                            width: '15px',
                            height: '15px',
                            border:"1px solid white",
                            backgroundColor: 'red',
                            borderRadius: '50%',
                            color: 'white',
                          }}></div>
                      )}
                      {showNotifePage && (
                        <div
                          ref={notificationRef}
                          style={{
                            zIndex: 9999,
                            top: 60,
                            right: 0,
                            paddingTop: '20px',
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'column',
                            // justifyContent: 'center',
                            position: 'absolute',
                            width: '250%',
                            height: '40rem',
                            cursor:"auto",
                            // paddingTop:"800px",
                            backgroundColor: 'white',
                            border: '1px solid black',
                            borderRadius: '5px',
                            animation: 'fadeInDown  .1s ',
                            boxShadow: '0px 0px 11px -7px black',
                            overflowY: 'scroll',
                          }}>
                          
                          <div
                            style={{
                              position: "absolute",
                              top: 0,
                              // backgroundColor:"#F1E4C3",
                              // overflowY:"scroll",
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems:"center",
                              paddingLeft: "10px",
                              width: "100%"
                            }}
                          >
                            <p style={{fontWeight: 300}}>
                              Liste des notifications
                            </p>
                            <p> <span style={{
                              fontWeight: 300,
                              
                              paddingRight: "10px"
                            }}>Total {total}</span></p>
                         

                             </div>
                          
                          {notificationsListe.length > 0 &&!loaderNotif ?
                          
                          <div style={{ width:"90%",marginTop:"15px", overflowY:"scroll"}}>
                              {
                                notificationsListe.map(data => (
                              
                                  <div
                                    key={data._id}
                                    style={{
                                      cursor: data.seen ? "default" : "pointer",
                                      opacity:!data.seen?1:0.7,

                                      backgroundColor: !data.seen ? '#F3F3F3' : "white", position: "relative"
                                    }}
                                    className="show-demande-items-notif"
                                    onClick={data.seen?()=>{}:() => {
                                      updateSeen(data._id,data)
                                      // fetchNotifications()
                                      
                                      setShowNotifePage(false);
                                    }}>
                                    
                                    <p style={{ marginBottom: 1 }}>
                                      {titleToMessage(data.payload.tag)}{' '}
                                    </p>
                                    <p style={{
                                      fontSize:"12px",
                                      marginBottom: 0, opacity: 0.7
                                    }}>
                                      {data.payload.from ||data.payload.full_name
                                        ? <span><span style={{ color: "#EE7214", textDecoration:"underline" }}>{data.payload.from||data.payload.full_name}{" "} </span> {tagoToMessage(data.payload.tag)}</span>
                                        : 'Un utilisateur ' +
                                        tagoToMessage(data.payload.tag)
                                      }
                                      {/* {data._id} */}
                                    </p>
                                    {!data.seen&&<span style={{
                                      position: "absolute", right: 40,
                                      borderRadius:"5px",
                                      backgroundColor: "orange", width: "10px", height: "10px"
                                    }}></span>}
                                  </div>
                               
                                ))
                              }
                              </div>
                            : loaderNotif ?
                            <LoadingOutlined />:
                              (
                            <div
                              style={{
                                  display: 'flex',
                                flexDirection:"column",
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}>
                              <img
                                style={{
                                  transform: 'rotate(-5deg)',
                                  opacity: '.5',
                                }}
                                width={150}
                                src={notifPng}></img>
                              <span
                                style={{
                                  opacity: 0.5,
                                  fontSize: 17,
                                  fontWeight: 300,
                                }}>
                                Vous recevrez vos notifications ici!
                              </span>
                            </div>
                          )}

                          {/* <img
                            style={{transform: 'rotate(-5deg)', opacity: '.5'}}
                            width={150}
                            src={notifPng}></img>
                          <span
                            style={{
                              opacity: 0.5,
                              fontSize: 17,
                              fontWeight: 300,
                            }}>
                            Vous recevrez vos notifications ici!
                          </span> */}
                          {notificationsListe.length != total && (
                            <div
                              className="more"
                              // style={{position: 'sticky', bottom: 10,right:0}}
                            >
                              <p
                                onClick={
                                  () => moreNotifications()
                                  // setShowNotifModal(true);
                                  // setShowNotifePage(false);
                                }
                                // onClick={()=>{setOpenNotification(!openNotification)}}
                                style={{
                                  opacity:.7,
                                  // background: 'rgb(2,0,36);',
                                  padding: '5px',
                                  borderRadius: '5px',
                                  // background: 'orange',
                                  fontSize: '15px',
                                  // textDecoration: 'underline',
                                  cursor: 'pointer',
                                  marginBottom: 1,
                                }}>
                                {loaderMore?<span> Chargement...</span>:"Voir plus"}
                              </p>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        size="lg"
        isOpen={showNotifModal}
        toggle={() => setShowNotifModal(false)}>
        <ModalHeader
          toggle={() => setShowNotifModal(false)}
          close={
            <button className="close" onClick={() => setShowNotifModal(false)}>
              &times;
            </button>
          }>
          Liste des notifications
        </ModalHeader>

        <ModalBody>
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              alignItems: 'center',
              width: '100%',
              height: '100%',
            }}>
            <div>Pas encore des Notifications </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={() => {
              setShowNotifModal(false);
            }}
            // disabled={loadingData}
            className="profile-btn-white">
            Ok
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default UserBox;

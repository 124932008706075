import React, {Fragment, useEffect, useState, useContext, useRef} from 'react';
import EditIcon from '@material-ui/icons/Edit';
import AppHeader from 'Layout/AppHeader/';
import AppSidebar from 'Layout/AppSidebar/';
import AppFooter from 'Layout/AppFooter/';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import {MySpinner, Input, DropDown} from 'components/atoms';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {debounce} from 'lodash';
import Select from 'react-select';
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  Spinner,
  CardBody,
  Card,
  Input as Input2,
  FormGroup,
} from 'reactstrap';
import InfoIcon from '@mui/icons-material/Info';
import CenterFormApi from 'services/api/CenterFormApi';
import {Datatable} from 'components/organisms';
import {AuthContext} from 'services';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import TocIcon from '@material-ui/icons/Toc';
import CustomCalendarHeader, {CustomDatePickerInput} from './customizeHead';
import {customValidationMessage} from 'components/atoms/Input';
import {grey, orange} from '@material-ui/core/colors';
import {customStylesTable} from 'shared/customTable';
import {CloseCircleOutlined} from '@ant-design/icons';
import {ToastContainer} from 'react-toastify';
import {showToast} from 'shared/extra';
import BasicDatePicker from 'shared/customDatePicker2';
import ButtonAntDesign from 'components/atoms/AntdButton';

const customStyles = {
  control: (provided, state) => {
    return {
      ...provided,
      // border: 'none',
      minHeight: '43px',
      height: '43px',
      marginTop: '38px',
      background: '#fbfbfb',
      //  border: '1px solid #d3d8dd',
      borderRadius: '5px',
      border: state.isFocused ? '1px solid orange' : '1px solid #d3d8dd',
      // outline:state.f ?1px solid orange
      fontFamily: 'Poppins-Regular',
    };
  },
  // option: (provided, state) => ({
  //   //...provided,
  //   // backgroundColor: state.isSelected ? '#333' : 'white',
  //   // color: state.isSelected ? 'white' : 'black',
  // }),
  // multiValue: (provided) => ({
  //   // ...provided,
  //   // backgroundColor: '#333',
  //   // color: 'white',
  // }),
};

const plan = {
  name: '',
  userType: '',
  price: '',
  duration: '',
  durationType: '',
};

const userTypesV2 = [
  {
    id: 'physical',
    name: 'Physique ',
  },
  {
    id: 'semi-physical',
    name: 'Semi-Physique',
  },
  {
    id: 'digital',
    name: 'Digital',
  },
];

const codePromoScreen = () => {
  const [loadingData, setLoadingData] = useState(false);
  const centerFormContext = useContext(AuthContext);
  const {user_data} = centerFormContext.state;
  const [sort, setSort] = useState({
    _id: -1,
  });
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(15);
  const [search, setSearch] = useState('');
  const [loadingTable, setLoadingTable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [onValid, SetOnvalid] = useState(false);
  const [data, setData] = useState([]);
  const [dataCode, setDataCode] = useState([]);
  const [selectedToModify, setSelectedToModify] = useState();
  const [selectedToDelete, setSelectedToDelete] = useState();
  const [showCal, setshowCal] = useState(false);
  const [value, onChange] = useState(new Date());
  const [error, setError] = useState(false);
  const [errorNbUsers, setErrorNbUsers] = useState(false);
  const [newCode, setNewCode] = useState({
    code: '',
    percent_off: '',
    expires_at: '',
    free_months: '',
    users_limits: '',
    to: [],
  });
  const [isSelectDisabled, setisSelectDisabled] = useState(false);
  const [errorSpace, setErrorSpace] = useState(false);
  const [errorCodeName, setErrorCodeName] = useState(false);
  const [errorLimiteNegatif, setErrorLimiteNegatif] = useState(false);
  const [errorRemiseNegatif, setErrorRemiseNegatif] = useState(false);
  const [errorRemiseMoisNegatif, setErrorRemiseMoisNegatif] = useState(false);
  const selectInputRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [disabledBtnCodestate, setdisabledBtnCodestate] = useState(true);

  const [newPlan, setNewPlan] = useState({
    ...plan,
  });
  const [selectedDate, setSelectedDate] = useState(null);

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [options, setOptions] = useState(null);
  const [serchVal, setserchVal] = useState(null);
  const [controller, setController] = useState(true);

  // // console.log("onValidonValidonValidonValid",onValid);
  useEffect(async () => {
    let isMounted = true; // A flag to track whether the component is still mounted

    try {
      const listOfUsers = await CenterFormApi.get(
        `api/back-office/fetch-users?search=${serchVal}`,
      );
      // Transform the API response to match the format expected by react-select
      const newObtions = listOfUsers.data.data.map(data => {
        return {value: data.email, label: `${data.firstName} ${data.lastName}`};
      });
      // // console.log(options);
      // // console.log(newObtions);
      setOptions(newObtions);
      // setOptions(newObtions);
    } catch (error) {
      // console.error('Error fetching options:', error.message);
    }

    return () => {
      // This cleanup function is called when the component unmounts
      isMounted = false; // Update the flag to indicate that the component is unmounted
    };
  }, [serchVal]);

  const handleSelectChange = selected => {
    // setIsLoading(true);
    // //todo get liste of digital user by 10

    setSelectedOptions(selected);
  };
  // // console.log(newCode.expires_at);
  let disabledBtnCode = newCode => {
    if (
      newCode.code &&
      newCode.expires_at &&
      (newCode.percent_off && newCode.free_months) &&
      !error &&
      !errorNbUsers &&
      !errorRemiseNegatif
    ) {
      return true;
    } else {
      return false;
    }
  };
  // // console.log(newCode.code);
  // // console.log(newCode.expires_at);
  // // console.log(newCode.percent_off);
  // // console.log(newCode.free_months);
  // // console.log(error);
  // // console.log(errorNbUsers);
  // // console.log(errorRemiseNegatif);
  // // console.log(disabledBtnCode(newCode));

  const fetchUsers = async selected => {
    setIsLoading(true);

    const listOfUsers = await CenterFormApi.get(
      `api/back-office/fetch-users?search=${selected}`,
    );
    const newObtions = listOfUsers.data.data.map(data => {
      return {value: data.email, label: `${data.firstName} ${data.lastName}`};
    });

    setIsLoading(false);
    setOptions(newObtions);
  };

  const fetchCode = async (page, limit, sort, search) => {
    setLoadingTable(true);
    setPage(page);
    setLimit(limit);
    setSort(sort);
    setSearch(search);
    try {
      const response = await CenterFormApi.get(
        `api/back-office/fetch-coupon?limit=${limit}&page=${page}&search=${search}`,
      );

      setDataCode(response.data.data);

      setTotalRows(response.data.total);
    } catch (e) {}
    setLoadingTable(false);
  };

  // const fetchPlans = async (page, limit, sort, search) => {
  //   setLoadingTable(true);
  //   setPage(page);
  //   setLimit(limit);
  //   setSort(sort);
  //   setSearch(search);
  //   try {
  //     const response = await CenterFormApi.get(
  //       `/fetchPlans?page=${page}&limit=${limit}&sort=${JSON.stringify(
  //         sort,
  //       )}&search=${search}`,
  //     );
  //     setData(response.data.data);
  //     setTotalRows(response.data.recordsTotal);
  //   } catch (e) { }
  //   setLoadingTable(false);
  // };
  const handlePageChange = page => {
    fetchCode(page, limit, sort, search);
  };

  const handlePerRowsChange = async (limit, page) => {
    fetchCode(page, limit, sort, search);
  };

  const handleSort = async (column, sortDirection) => {
    fetchfetchPlans(
      page,
      limit,
      {[column.fieldName]: sortDirection === 'desc' ? -1 : 1},
      search,
    );
  };

  const columns = [
    // {

    //   name: 'ID',
    //   selector: row => new Date(row.createdAt).getTime(),
    //   sortable: false,
    //   fieldName: '_id',
    // },
    {
      name: 'Nom de code',
      selector: row => row.name,
      sortable: false,
      fieldName: 'code',
      cell: row => {
        return (
          <>
            <div
              style={{
                minWidth: '80px',
                //  background: '#39A7FF',
                color: 'black',
                padding: '6px',
                fontSize: '16px',
                borderRadius: '5px',
                textAlign: 'center',
                fontWeight: 900,
              }}>
              {row.code}
            </div>
          </>
        );
      },
      // onMouseEnter: () => // console.log('Mouse entered cell'),
      style: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'black',
      },
    },
    {
      name: 'Remsie %',
      selector: row => row.percent_off,
      sortable: false,
      fieldName: 'percent_off',
      cell: row => {
        return (
          <>
            <p>{row.percent_off || '-'}</p>
          </>
        );
      },
      style: {display: 'flex', alignItems: 'center', justifyContent: 'center'},
    },
    {
      name: 'Nombre de mois',
      selector: row => row.free_months,
      sortable: false,
      fieldName: 'free_months',
      cell: row => {
        return (
          <>
            <p>{row.free_months || '-'}</p>
          </>
        );
      },
      style: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'black',
      },
    },

    {
      name: 'Limité à/aux',

      selector: row => row.expires_at,
      sortable: false,
      fieldName: 'users_limits',
      cell: row => {
        return (
          <>
            <p>{row.users_limits || '-'}</p>
          </>
        );
      },
      style: {display: 'flex', alignItems: 'center', justifyContent: 'center'},
    },
    {
      name: 'Lié au/aux',
      selector: row => row.to,
      sortable: false,
      fieldName: 'to',
      cell: row => {
        return (
          <div style={{display: 'flex', flexDirection: 'column'}}>
            {row.to.length > 0
              ? row.to.map((data, i) => {
                  if (data) {
                    return (
                      <div
                        key={i}
                        style={{
                          minWidth: '200px',
                          marginBottom: '5px',
                          background: '#FE6244',
                          color: 'white',
                          padding: '3px',
                          borderRadius: '5px',
                          textAlign: 'center',
                        }}>
                        {data}
                      </div>
                    );
                  } else {
                    return '-';
                  }
                })
              : '-'}
          </div>
        );
      },
      width: '300px',
      style: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'black',
      },
    },

    {
      name: 'Date de création ',
      selector: row => row.createdAt,
      sortable: false,
      fieldName: 'createdAt',
      cell: row => {
        return (
          <>
            <p>{new Date(row.createdAt).toLocaleDateString('fr') || '-'}</p>
          </>
        );
      },
      style: {display: 'flex', alignItems: 'center', justifyContent: 'center'},
    },
    {
      name: "Date d'expiration",
      selector: row => row.expires_at,
      sortable: false,
      fieldName: 'expires_at',
      cell: row => {
        return (
          <>
            <p>{new Date(row.expires_at).toLocaleDateString('fr') || '-'}</p>
          </>
        );
      },
      style: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'black',
      },
    },

    {
      style: {display: 'flex', alignItems: 'center', justifyContent: 'center'},

      name: 'Action',
      cell: row => {
        return user_data.type === 'admin' ? (
          <>
            {/* <button
              onClick={() => {
                setSelectedToModify({
                  ...row,
                  userType: userTypesV2.filter(
                    item => item.id === row.userType,
                  )[0],
                  durationType: durationTypes.filter(
                    item => item.id === row.durationType,
                  )[0],
                });
              }}
              className="action-button"
              title="Modifier">
              <span className="lnr-pencil" />
            </button> */}
            <button
              onClick={() => {
                setSelectedToDelete(row.code);
              }}
              className="action-button"
              title="Effacer">
              <CloseCircleOutlined style={{color: 'red'}} />
            </button>
          </>
        ) : null;
      },
    },
  ];

  const searchTable = debounce(async e => {
    fetchCode(1, limit, 1, e.target.value);
  }, 50);

  useEffect(() => {
    fetchCode(1, limit, 1, search);
    fetchUsers('');
    // fetchCode(1, 10, sort, search); // fetch page 1 of users
  }, []);

  const dismissDeleteModal = () => {
    setSelectedToDelete(null);
  };

 
  const submitDeleteForm = async e => {
    e.preventDefault();
    setLoading(true);

    try {
      // // console.log('inside delete');
      const res = await CenterFormApi.post('/api/back-office/delete-coupon', {
        code: selectedToDelete.toString(),
      });
      showToast('success', 'Le code promo a été supprimé avec succès');
      // // console.log('res222', res);
      dismissDeleteModal();
      setDataCode(res.data.data);
      fetchCode(page, limit, sort, search);
    } catch (e) {}
    setLoading(false);
  };

  // const onClear = () => {
  //   // console.log('rrr');
  //   selectInputRef.current.select.clearValue();
  // };

  //!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

  // // console.log("newCodenewCodenewCodenewCode : ",newCode);

  const handleSubmit = async e => {
    e.preventDefault();
    let CodeErr = false;
    const response = await CenterFormApi.get(
      `api/back-office/fetch-coupon?limit=${99999}&page=${page}&search=${
        newCode.code
      }`,
    );

    // // console.log('data', response.data?.data);
    if (response.data?.data?.length > 0) {
      setErrorCodeName(true);
      CodeErr = true;
    }
    // // console.log(!error && !CodeErr);
    if (!error && !CodeErr) {
      //
      setErrorCodeName(false);
      setLoading(true);

      // try {
      // ;
      let users = [];
      selectedOptions?.map(data => {
        return users.push(data.value);
      });
      newCode['to'] = users;

      const created = await CenterFormApi.post(
        '/api/back-office/create-coupon',
        {
          ...newCode,
        },
      );
      setNewCode({...newCode});
      if (created) {
        showToast('success', 'Le code promo a été crée avec succès');

        setNewCode({
          code: '',
          free_months: '',
          expires_at: null,
          users_limits: '',
          percent_off: '',
        });
        setisSelectDisabled(false);
        setSelectedOptions(null);
        setSelectedDate('');
        fetchCode(1, 10, 1, search);
        setNewCode({});
        // disabledBtnCode=true
      } else {
        showToast('error', 'error se produit !');
      }

      // onClear();
      // } catch (e) {
      // // console.log(e);
      // }
      setLoading(false);
    } else {
      // disabledBtnCode;
    }
    setController(true);
  };

  

  const onInputChangeCode = async (e, target) => {
    if (target == 'expires_at') {
      setNewCode({
        ...newCode,
        [target]: e,
      });
    } else {
      setNewCode({
        ...newCode,
        [target]: e.target.value,
      });
    }

    const pattern = /\b(?:10|20|30|40|50|60|70|80|90|100)\b/;
    if (target == 'code') {
      if (/\s/.test(e.target.value)) {
        setErrorSpace(true);
        setError(true);
      } else {
        setErrorSpace(false);
        setError(false);
      }
    }
    if (target == 'free_months') {
      setErrorRemiseMoisNegatif(false);
      if (e.target.value <= 0) {
        setErrorRemiseMoisNegatif(true);
      } else {
        setErrorRemiseMoisNegatif(false);
      }
    }
    if (target == 'users_limits') {
      // // console.log(error);

      if (e.target.value == '') {
        setErrorLimiteNegatif(false);
      }
      if (e.target.value && e.target.value <= 0) {
        setErrorNbUsers(true);
        setErrorLimiteNegatif(true);
      } else {
        setErrorNbUsers(false);
        setErrorLimiteNegatif(false);
      }
      if (e.target.value && e.target.value > 0) {
        setisSelectDisabled(true);
      } else {
        setisSelectDisabled(false);
      }
    }
    if (target == 'percent_off') {
      // // console.log(e.target.value);
      if (e.target.value && e.target.value <= 0) {
        setErrorRemiseNegatif(true);
      } else {
        setErrorRemiseNegatif(false);
      }

      if (e.target.value.length >= 1) {
        if (pattern.test(e.target.value)) {
          setError(false);
        } else {
          setError(true);
        }
      } else {
        setError(false);
      }
    }
  };

  




  const conditionalRowStyles = [
    {
      when: row => row.toggleSelected,
      style: {
        backgroundColor: 'orange',
        userSelect: 'none',
      },
    },
  ];

  const onGetDate = e => {
    setSelectedDate(e);
    onInputChangeCode(new Date(e).toISOString(), 'expires_at');
    // getValue(e)
  };

  return (
    <Fragment>
      {/* <AppHeader pageTitle="Code Promo" /> */}
      <div
      // className="app-main ecs-main"
      // style={{ background: "white" }}
      >
        {/* <AppSidebar /> */}
        <div
        // className="app-main__outer"
        >
          <div
          // className="app-main__inner"
          >
            {/* <MySpinner active={loadingData} /> */}
            <ReactCSSTransitionGroup
              component="div"
              transitionName="TabsAnimation"
              transitionAppear={true}
              transitionAppearTimeout={0}
              transitionEnter={false}
              transitionLeave={false}>
              <Row>
                <Col sm={3}>
                  <Card
                    style={{
                      boxShadow: 'none',
                      borderTop: '1px solid #bfbfbf38',
                      borderBottom: '1px solid #bfbfbf38',
                    }}
                    // style={{}}

                    className="card-container"
                    // style={{border: '1px solid gray',}}
                  >
                    <label className="card-label2">
                      Créer un nouveau code promo
                    </label>
                    <CardBody style={{border: '1px solid white'}}>
                      {
                        // controller &&
                        <Form
                          onSubmit={handleSubmit}
                          className="ecs-form"
                          style={{border: '1px solid white'}}>
                          <div className="card-header"></div>
                          <FormGroup style={{borderWidth: '20%'}} row>
                            <Col
                              lg={20}
                              // style={{ display:"flex",justifyContent:"center",alignItems:"center", width:"100%",border: "1px solid red" }}
                            >
                              <Col>
                                <Row>
                                  <div
                                     style={{
                                      // position: 'relative',
                                      marginBottom: '0px',
                                      // border: '1px solid blue',
                                      //height: '100%',
                                    }}
                                    className="form-group">
                                    <Input
                                      onChange={e =>
                                        onInputChangeCode(e, 'code')
                                      }
                                      value={newCode.code}
                                      label="Nom du code *"
                                      type="text"
                                      required
                                    />
                                  </div>
                                </Row>




                                <Row>
                                  {/* <div className="form-group">
                                <Input
                                  onChange={e =>
                                    onInputChangeCode(e, 'users_limits')
                                  }
                                  min={0}
                                  value={newCode.users_limits}
                                  label="Nombre de utilisteurs"
                                  type="number"
                                  required={false}
                                  disabled={
                                    selectedOptions?.length > 0 ? true : false
                                  }
                                />
                              </div> */}
                                  <div
                                    className="ecs-group"
                                    style={{
                                      position: 'relative',
                                      marginBottom: '25px',
                                      // border: '1px solid blue',
                                      //height: '100%',
                                    }}>
                                    <span
                                      style={{
                                        position: 'absolute',
                                        top: '16px',
                                        // maxheight: '25px',
                                        marginLeft: '5px',
                                        fontFamily: 'Poppins-Regular',
                                        fontSize: '14px',
                                        color: newCode?.users_limits
                                          ? '#d5d9eb'
                                          : 'black',
                                      }}>
                                      Sélectionner des utilisateurs
                                    </span>
                                    <Select
                                      isLoading={isLoading}
                                      // ref={selectInputRef}
                                      noOptionsMessage={({inputValue}) => {
                                        return !inputValue
                                          ? options
                                          : 'Aucun résultat trouvé';
                                      }}
                                      isDisabled={isSelectDisabled}
                                      styles={customStyles}
                                      isMulti
                                      onInputChange={e => {
                                        setserchVal(e);
                                      }}
                                      // hideSelectedOptions={false}
                                      options={options}
                                      value={selectedOptions}
                                      // onInputChange={handleSelectChangeInput}
                                      onChange={handleSelectChange}
                                      placeholder=""
                                      // isSearchable
                                      isClearable={false}
                                      theme={theme => ({
                                        ...theme,
                                        borderRadius: 0,

                                        colors: {
                                          ...theme.colors,
                                          primary25: 'white',
                                          primary: 'white',
                                        },
                                      })}
                                    />
                                  </div>
                                </Row>

                              
                              
                              
                              
                              
                              
                              
                              
                              
                              
                              
                              
                              
                              
                                <Row className="mobile">
                                  <div
                                    className="form-group2"
                                    style={
                                      {
                                        // marginTop:"10px"
                                        // border: '1px solid red',
                                      }
                                    }>
                                    <Input
                                      required
                                      onChange={e =>
                                        onInputChangeCode(e, 'percent_off')
                                      }
                                      min={0}
                                      value={newCode.percent_off}
                                      label="Remise en % *"
                                      type="number"
                                      step="10"
                                      // disabled={
                                        // newCode.free_months ? true : false
                                      // }
                                      pattern="/\b(?:10|20|30|40|50|60|70|80|90|100)\b"
                                    />
                                  </div>
                                </Row>
                                <Row>
                                  <div>
                                    <span
                                      style={{
                                        // border:"1px solid red",
                                        marginLeft: '5px',
                                        // marginBottom: "-5px",
                                        marginTop: '6px',
                                        fontFamily: 'Poppins-Regular',
                                        fontSize: '13px',
                                        color: 'black',
                                      }}>
                                      Date d'expiration *{' '}
                                    </span>
                                    <div className="form-group2">
                                      <BasicDatePicker
                                        min={new Date()}
                                        required
                                        onGetDate={onGetDate}
                                        fromvalue={selectedDate}
                                      />
                                    </div>

                                    {/* <DatePicker
                                    title="Tappez la date d'expiration ici"
                                    calendarClassName="custom-calendar"
                                    minDate={new Date()}
                                    def
                                    selected={selectedDate}
                                    onChangeRaw={(e) => {
                                      // // console.log("onchange input out : ",e);
                                      //  e.setCustomValidity('')
                                    }}
                                    onChange={e => {
                                      handleDateChange(e, 'expires_at')
                                      // // console.log("onchange input out : ",e);
                                    }
                                     
                                    }

                                    onInvalid={(e) => {
                                      // console.log('Validation failed:', e.target.validationMessage);
                                    }}

                                    // onInvalid={e => {
                                    //   // console.log(!e.target.value);
                                    //   if (!e.target.value) {
                                    //     e.target.setCustomValidity('Ce champ est obligatoire!');
                                    //   } else if (e.target.value) {
                                    //     // e.preventDefault();
                                    //     e.target.setCustomValidity('');
                                    //   }
                                    // }}


                                    locale={'fr'}
                                    required={selectedDate?false:true}
                                    defaultShow={''}
                                    dateFormat="d MMMM  yyyy"
                                    dayClassName={date =>
                                      date.toDateString() ===
                                      selectedDate?.toDateString()
                                        ? 'selected-day'
                                        : date.toDateString() ==
                                            new Date().toDateString() &&
                                          !selectedDate
                                        ? 'selected-day'
                                        : undefined
                                    }
                                    customInput={
                                      <CustomDatePickerInput
                                        // onChange={
                                        //   (e) => {
                                           
                                        //     if (e.target.value) { ;
                                        //   }
                                        //   }
                                        // }
                                        // onInputChange={(e) => {
                                        //   // console.log(e);
                                        // }}
                                        // value={selectedDate}
                                        customValidityMessage="Ce champ est obligatoire"
                                      />
                                    }
                             
                                  /> */}
                                  </div>
                                </Row>
                                <Row
                                  className="mobile"
                                  // sm={4}
                                  style={{
                                    // borderLeft: '1px solid orange',
                                    marginTop: '15px',
                                    // height:"43px"
                                  }}>
                                  <div className="form-group">
                                    <Input
                                      onChange={e =>
                                        onInputChangeCode(e, 'users_limits')
                                      }
                                      min={0}
                                      max={999999}
                                      value={newCode.users_limits}
                                      label="Nombre d’utilisateurs"
                                      type="number"
                                      required={false}
                                      disabled={
                                        selectedOptions?.length > 0
                                          ? true
                                          : false
                                      }
                                    />
                                  </div>
                                </Row>
                                <Row>
                                  <div
                                    className="form-group2"
                                    style={{
                                      marginTop: '10.5px',
                                      // border: '1px solid red',
                                      // height: '56px',
                                    }}>
                                    <Input
                                      onChange={e =>
                                        onInputChangeCode(e, 'free_months')
                                      }
                                      min={0}
                                      value={newCode.free_months}
                                      label="Nombre de mois remisé *"
                                      type="number"
                                      required={false}
                                      // disabled={
                                        // newCode.percent_off ? true : false
                                      // }
                                    />
                                  </div>
                                </Row>
                              </Col>
                              {error && (
                                <p
                                  style={{
                                    fontSize: '13px',
                                    margin: 0,
                                    padding: 0,
                                    color:"red",
                                    // color: '#000000c2',
                                  }}>
                                  {' '}
                                  🛑 La valeur que vous avez fournie n'est pas
                                  valide. Veuillez saisir l'une des valeurs
                                  autorisées : 10, 20, 30, 40, 50, 60, 70, 80,
                                  90 ou 100.
                                </p>
                              )}
                              {errorCodeName && (
                                <p
                                  style={{
                                    fontSize: '13px',
                                    margin: 0,
                                    padding: 0,
                                    color:"red",
                                    // color: '#000000c2',
                                  }}>
                                  {' '}
                                  🛑Erreur : Le code promo avec le nom{' '}
                                  <span style={{fontWeight: 500}}>
                                    {newCode.code}
                                  </span>{' '}
                                  existe déjà. Veuillez choisir un nom
                                  différent.
                                </p>
                              )}
                              {(newCode?.free_months ||
                                newCode?.percent_off) && (
                                <p
                                  style={{
                                    fontSize: '13px',
                                    margin: 0,
                                    padding: 0,
                                    color: '#000000c2',
                                  }}>
                                  <InfoIcon style={{width: '15px'}} /> Le code
                                  promo offre uniquement une remise gratuite
                                  d'une durée en mois{' '}
                                  <span style={{fontWeight: 500}}>ET</span> un
                                  pourcentage de réduction.
                                </p>
                              )}

                              {(newCode?.users_limits ||
                                selectedOptions?.length > 0) && (
                                <p
                                  style={{
                                    fontSize: '13px',
                                    margin: 0,
                                    padding: 0,

                                    color: '#000000c2',
                                  }}>
                                  <InfoIcon style={{width: '15px'}} /> Le code
                                  promo est spécifique pour un certain
                                  utilisateur{' '}
                                  <span style={{fontWeight: 500}}>OU</span> un
                                  nombre limité de premiers utilisateurs.
                                </p>
                              )}

                              {errorLimiteNegatif && newCode.users_limits && (
                                <p
                                  style={{
                                    fontSize: '13px',
                                    margin: 0,
                                    padding: 0,
                                    // color: '#000000c2',
                                    color:"red",
                                  }}>
                                  🛑 Veuillez noter que le nombre d'utilisateurs
                                  ne doit pas être inférieur ou égale à 0.
                                </p>
                              )}
                              {errorRemiseNegatif && newCode.percent_off && (
                                <p
                                  style={{
                                    fontSize: '13px',
                                    margin: 0,
                                    padding: 0,
                                    // color: '#000000c2',
                                    color:"red",
                                  }}>
                                  🛑 Veuillez noter que la valeur de remise en %
                                  ne doit pas être inférieur ou égale à 0.
                                </p>
                              )}
                              {errorRemiseMoisNegatif && newCode.free_months && (
                                <p
                                  style={{
                                    fontSize: '13px',
                                    margin: 0,
                                    padding: 0,
                                    // color: '#000000c2',
                                    color:"red",
                                  }}>
                                  🛑 Veuillez noter que le nombre de remise en
                                  mois ne doit pas être inférieur ou égale à 0.
                                </p>
                              )}
                              {errorSpace && (
                                <p
                                  style={{
                                    fontSize: '13px',
                                    margin: 0,
                                    padding: 0,
                                    // color: '#000000c2',
                                    color:"red",
                                  }}>
                                  🛑 Attention, le code promo. ne doit pas
                                  contenir d'espaces.
                                </p>
                              )}
                            </Col>
                          </FormGroup>

                          {disabledBtnCode(newCode) ? (
                            <ButtonAntDesign
                              color={'orange'}
                              type="primary"
                              style={{
                                color: 'black',
                                border: '1px solid black',
                                marginLeft: 'auto',
                                display: 'block',
                              }}
                              colorBgContainer={'#ce93d8'}
                              icon={
                                loading && (
                                  <span style={{marginRight: '.5rem'}}>
                                    <Spinner
                                      as="span"
                                      variant="warning"
                                      size="sm"
                                      role="status"
                                      aria-hidden="true"
                                      animation="grow"
                                    />{' '}
                                  </span>
                                )
                              }
                              text={'Créer un nouveau code'}
                              onClick={handleSubmit}
                            />
                          ) : (
                            // <Button
                            //   style={{
                            //     marginLeft: 'auto',
                            //     display: 'block',
                            //     width: '20%',
                            //     backgroundColor: 'orange',
                            //   }}
                            //   disabled={loading && !disabledBtnCode}
                            //   type="submit"
                            //   className={`btn-wide ecs-save-btn mb-2  ${
                            //     disabledBtnCode ? 'disabledBtn' : ''
                            //   }`}
                            //   size="lg">
                            // {loading ? (
                            //   <span style={{marginRight: '.5rem'}}>
                            //     <Spinner
                            //       as="span"
                            //       variant="warning"
                            //       size="sm"
                            //       role="status"
                            //       aria-hidden="true"
                            //       animation="grow"
                            //     />{' '}
                            //   </span>
                            // ) : null}
                            //   Créer
                            // </Button>
                            <ButtonAntDesign
                              color={'orange'}
                              disabled={true}
                              type="primary"
                              style={{
                                color: 'black',
                                // border:"1px solid black",
                                marginLeft: 'auto',
                                display: 'block',
                              }}
                              // colorBgContainer={'#ce93d8'}
                              text={'Créer un nouveau code'}
                              onClick={() => {}}
                            />
                            // <div
                            //   style={{
                            //     marginLeft: 'auto',
                            //     display: 'block',
                            //     width: '20%',
                            //     textAlign: 'center',
                            //     border: '1px solid gray',
                            //     borderRadius: '5px',
                            //     cursor: 'not-allowed',
                            //     opacity: '.5',
                            //     // backgroundColor: 'orange',
                            //   }}>
                            //   Créer
                            // </div>
                          )}
                        </Form>
                      }
                    </CardBody>
                  </Card>
                </Col>

                <Col>
                  <Card
                    //  className="Card"
                    style={{
                      borderLeft: 'none',
                      boxShadow: 'none',
                      borderTop: '1px solid #bfbfbf38',
                      borderBottom: '1px solid #bfbfbf38',
                    }}>
                    <CardBody className="users-table">
                      <div className="tableHeader">
                        <label className="card-title">
                          Liste de code promo
                        </label>
                        <Input2
                          onChange={searchTable}
                          type="text"
                          placeholder="Recherche..."
                        />
                      </div>
                      {dataCode.length > 0 ? (
                        <Datatable
                          // title={

                          // }
                          columns={columns}
                          data={dataCode}
                          paginationPerPage={limit}
                          progressPending={loadingTable}
                          paginationTotalRows={totalRows}
                          onChangeRowsPerPage={handlePerRowsChange}
                          onChangePage={handlePageChange}
                          onSort={handleSort}
                          defaultSortFieldId={1}
                          defaultSortAsc={false}
                          conditionalRowStyles={conditionalRowStyles}
                          customStyles={customStylesTable({pointer: true})}
                        />
                      ) : (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                              alignItems: 'center',
                              fontStyle:"italic"
                            
                          }}>
                          <p>Pas encore de données !</p>
                        </div>
                      )}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <ToastContainer />
            </ReactCSSTransitionGroup>
          </div>
          {/* <AppFooter /> */}
        </div>
      </div>

      <Modal
        size="lg"
        isOpen={selectedToDelete ? true : false}
        toggle={!loading ? dismissDeleteModal : null}>
        <ModalHeader
          toggle={dismissDeleteModal}
          close={
            <button className="close" onClick={dismissDeleteModal}>
              &times;
            </button>
          }>
          Suppression code promo
        </ModalHeader>
        <Form onSubmit={submitDeleteForm}>
          <ModalBody>
            <p className="modalMessage">
              Êtes-vous sûr de vouloir supprimer ce code ?
            </p>
          </ModalBody>
          <ModalFooter>
            <Button
              disabled={loading}
              color="link"
              onClick={dismissDeleteModal}>
              Annuler
            </Button>
            <Button
              disabled={loading}
              type="submit"
              className="btn-wide mb-2 mr-2"
              size="lg"
              color="danger">
              {loading ? (
                <span style={{marginRight: '.5rem'}}>
                  <Spinner
                    as="span"
                    variant="warning"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    animation="grow"
                  />{' '}
                </span>
              ) : null}
              Effacer
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </Fragment>
  );
};

export default codePromoScreen;

import React, {Fragment} from 'react';

class AppFooter extends React.Component {
  render() {
    return (
      <Fragment>
        <div className="app-footer">
          <div className="app-footer__inner">
            <div className="app-footer-left">
              <ul className="nav">
                <li className="nav-item" style={{fontStyle:"italic"}}>
                © 2021-{new Date().getFullYear().toString()} Center-Form Entreprise, Tous droits réservés.
                </li>
              </ul>
            </div>
            <div className="app-footer-right">
              <ul className="nav">
                <li style={{display: 'flex',fontStyle:"italic" }} className="nav-item">
                  {"Développé par "}
                 <a
                    href="https://kwantic.fr/"
                    target="_blank"
                    className="nav-link"
                    style={{
                      fontStyle:"normal",
                      marginLeft: "5px"
                    }}
                  >
                      {" "} KWANTIC
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default AppFooter;

export const bmiCalculator = ({weight, height, weightUnit, heightUnit}) => {
  // const weightKG = weightUnit === 'KG' ? weight : weight / 2.205;
  // const heightCM = heightUnit === 'CM' ? height : height * 30.48;
  // console.log(weight,height);
  const res=parseFloat(((weight / Math.pow(height / 100, 2))).toFixed(1));
  return  res?res:0;

// // console.log(parseFloat(((weight / Math.pow(height/100,2))).toFixed(1)));
};


export function idealWeightProfile(heightCm, gender) {
  // // console.log(gender);
  let heightInches = heightCm / 2.54;
  let idealWeight;

  if (gender === 'male') {
      idealWeight = 50 + 2.3 * (heightInches - 60);
  } else if (gender === 'female') {
      idealWeight = 45.5 + 2.3 * (heightInches - 60);
  } else {
    idealWeight = 50 + 2.3 * (heightInches - 60);
  }
// // console.log(idealWeight.toFixed(0));
  return idealWeight.toFixed(0); // return the result rounded to 2 decimal places
}

export const idealWeight = ({ gender, height, heightUnit }) => {
  // // console.log(gender,height);
  let heightInches = height / 2.54;
  let idealWeight;

  if (gender === 'male') {
      idealWeight = 50 + 2.3 * (heightInches - 60);
  } else if (gender === 'female') {
      idealWeight = 45.5 + 2.3 * (heightInches - 60);
  } else {
    idealWeight = 50 + 2.3 * (heightInches - 60);
  }

  return idealWeight.toFixed(0);
 
 
  // const idealWeight = weight * 0.9; // Example: 90% of the entered weight
  // // return idealWeight;
  // // console.log(height);
  // const heightCM = heightUnit === 'CM' ? height : height * 30.48;

  // if (gender === 'male') {
  //   return Math.round((50 + 0.91 * (heightCM - 152.4)).toFixed(1));
  // } else {
  //   return Math.round((45.5 + 0.91 * (heightCM - 152.4)).toFixed(1));
  // }
};





export const idealWeightLB = ({gender, height, heightUnit}) => {
  const heightCM = heightUnit === 'CM' ? height : height * 30.48;

  if (gender === 'male') {
    return Math.round((50 + 0.91 * (heightCM - 152.4) * 2.205).toFixed(1));
  } else {
    return Math.round((45.5 + 0.91 * (heightCM - 152.4) * 2.205).toFixed(1));
  }
};

import {CommentOutlined, LoadingOutlined, SendOutlined} from '@ant-design/icons';
import TextArea from 'antd/es/input/TextArea';
import React, {useState, useEffect, useContext} from 'react';
import {Card, CardBody, Col, Row} from 'reactstrap';
import {diffDate, emojis} from 'shared/extra';
import {Button} from 'antd';
import moment from 'moment';
import loaders from '../../shared/delivery-truck.gif'

function makeLinkClickable(inputString) {
  if (inputString.startsWith("http://") || inputString.startsWith("https://")) {
    return <a style={{ textDecoration: "underline"}} href={inputString}target="_blank">{inputString}</a>;
  } else {
    return <p style={{margin:0,paddingBottom:4}}>{inputString}</p>;
  }
}


const UserChatPanel = ({
  messageHere,
  sendMessage,
  showConversation,
  newListeMessage,
  keys,
  chatBoxRef,
  bg,
  handleClick,
  setNewMessage,
  newMessage,
  divRef,
  profileImg,
  sound,
  emptyConversation,
  setemptyConversation,
  channelId,
  loadingChange
  
}) => {
 
   const [ListeMessageInner,setListeMessageInner]=useState(newListeMessage)
  const playSound = () => {
    sound.play();
  };

  useEffect(() => {
    setListeMessageInner(newListeMessage)
  },[newListeMessage])
  
// console.log("inner ",newListeMessage,ListeMessageInner);
  
  if (loadingChange) {
    return (<div
      className="show-demande-sub-container-right-msg"
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        animation: 'fadeIn 1s',
      }}  
    >
    <LoadingOutlined width={"50px"}/></div>)
  } else return (
    <>
      {(ListeMessageInner.length > 0  || !emptyConversation)? (
      
        <div className="show-demande-sub-container-right-msg">
          <Col lg={12}>
            <Row lg={12}>
              <Card
                //  key={keys}
                style={{
                  animation: 'fadeIn .1s',
                  boxShadow: 'none',
                  margin: '5px 1px 10px 1px',
                  width: '99%',
                  height: '700px',
                  border: '1px solid #80DEEA',
                  backgroundColor:"#ffb30008",
                  // backgroundImage: 'urL(' + bg + ')',
                  boxShadow: 'inset gray 0px 0px 9px -5px',
                }}>
                <CardBody
                  ref={chatBoxRef}
                  style={{
                    margin: '5px',
                    padding: '10px',
                    overflowY: 'auto',
                    display: 'flex',
                    flexDirection: 'column-reverse',
                  }}>
                  {ListeMessageInner
                    ? ListeMessageInner.map((data, i) => {
                        if (data.from_user == false) {
                          return (
                            <div
                              // ref={chatBoxRef}
                              key={i}
                              className="self-right-msg-container">
                              <div key={i * 2} className="msg">
                                <p
                                  // key={keys}
                                  style={{
                                    animation: 'fadeIntranslate .5s',
                                    backgroundColor: '#FFECD6',
                                    padding: '10px',
                                    margin: '0 0 0 3px',
                                    maxWidth:"500px",
                                    borderTopRightRadius: '25px',
                                    borderTopLeftRadius: '25px',
                                    borderBottomLeftRadius: '25px',
                                    boxShadow: 'black 0px 0px 11px -6px',
                                  }}>
                                  {makeLinkClickable(data.message)}
                                </p>
                              </div>
                            </div>
                          );
                        } else {
                          return (
                            <>
                              {diffDate(new Date(), data.createdAt) >= 1 &&
                                data.from_user == true && (
                                  <div
                                    key={i * 10}
                                  style={{
                                    animation: 'fadeIntranslate .5s',
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      marginTop: '50px',
                                    }}>
                                    <p
                                      key={keys}
                                      style={{
                                        animation: 'fadeIn 1s',
                                        // border:"1px solid red",
                                        fontSize: '10px',
                                        color: 'black',
                                        backgroundColor: 'white',
                                        borderRadius: '5px',
                                        padding: '1px 5px',
                                      }}>
                                      {moment(data.createdAt).format(
                                        ' DD-MM-YYYY - hh:mm:ss',
                                      )}{' '}
                                    </p>
                                  </div>
                                )}
                              <div
                                key={i * 100}
                                className="self-left-msg-container">
                                <div key={keys} ref={divRef} className="msg">
                                  <img
                                    key={i * 25}
                                    className="rounded-image-small"
                                    src={profileImg}></img>
                                  <div
                                    key={i * 3}
                                    style={{
                                      animation: 'fadeIntranslate 1s',
                                      borderTopLeftRadius: '0px',
                                      backgroundColor: '#FF9800',
                                      padding: '10px',
                                      opacity: 1,
                                      maxWidth:"500px",
                                      margin: '20px 0 0 5px',
                                      color: 'black',
                                      borderBottomLeftRadius: '25px',
                                      borderBottomRightRadius: '25px',
                                      borderTopRightRadius: '25px',
                                      boxShadow: 'black 0px 0px 11px -6px',
                                    }}>
                                  {makeLinkClickable(data.message)}
                                  </div>
                                </div>
                              </div>
                            </>
                          );
                        }
                      })
                    : 'no message yet'}
                </CardBody>
              </Card>
            </Row>
            <Row>
              <div
                style={{
                  display: 'flex',
                  // alignItems: 'center',
                  fontSize: '10px',
                  width: '100%',
                  margin: '5px 0px',
                  padding: '5px',
                  // border: '1px solid red',
                  gap: 10,
                  overflow: 'scroll',
                  // justifyContent: "space-between",
                }}>
                {' '}
                {emojis.map(item => (
                  <span
                    key={item.id}
                    style={{
                      userSelect: 'none',
                      fontSize: '2em',
                      margin: '5px',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      handleClick(item.emoji);
                      playSound();
                    }}>
                    {item.emoji}
                  </span>
                ))}
              </div>
            </Row>
            <Row style={{}}>
              <div
                style={{
                  padding: 0,
                  margin: 0,
                  display: 'flex',
                  // alignItems: 'center',
                  width: '100%',
                  // border: '1px solid red',
                  gap: 10,
                  // justifyContent: "space-between",
                }}>
                <TextArea
                  value={newMessage}
                  onChange={e => setNewMessage(e.target.value)}
                  rows={20}
                  style={{
                    flex: '20',
                    fontSize: '17px',
                    height: '70px',
                  }}
                  placeholder="Ecrire un message ..."
                  maxLength={5000}
                />
                <Button
                  style={{
                    flex: '1',
                    padding: 0,
                    height: '70px',
                    backgroundColor: 'orange',
                    display: 'flex',
                    fontSize: '30px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    transform: !newMessage ? 'rotate(90deg)' : 'rotate(0deg)',
                  }}
                  onClick={() => {
                    sendMessage(showConversation.id,channelId);
                  }}
                  disabled={!newMessage ? true : false}
                  type="primary">
                  <SendOutlined />
                </Button>
                &nbsp; &nbsp;
              </div>
            </Row>
          </Col>
        </div>
      ) : (
        <div
          className="show-demande-sub-container-right-msg"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            animation: 'fadeIn 1s',
          }}>
            <div
             style={{ fontSize: "50px" }} 
            >

            <CommentOutlined height={100} width={100} />
            </div>
            {/* <img
            style={{
              width: '20%',

              opacity: 0.2,
            }}
            src={messageHere}
          /> */}
          <p className="p" style={{fontSize: '17px'}}>
            Pas encore des messages avec{' '}
            <span style={{fontWeight: 700}}>
              {showConversation.firstName} {showConversation.lastName}
            </span>{' '}
            <div style={{textAlign: 'center'}}>
              Vous recevrez votre message ici!
            </div>
          </p>
          <Button
            onClick={() => {
              setemptyConversation(false)
            }}
            style={{marginTop: '20px', color: 'orange'}}>
            Démarrer une conversation
          </Button>
        </div>
      )}
    </>
  );
};

export default UserChatPanel;


import { LoadingOutlined } from '@ant-design/icons';
import React, {useState, useRef, useEffect} from 'react';

const DropDown = ({
  id,
  label,
  icon,
  value,
  onClick,
  ref,
  disabled,
  data,
  readOnly = 'readOnly',
  onChangethis,
  loderUser,
  ...props
}) => {
 
  const [showDropDown, setShowDropDown] = useState(false);
  const dropRef = useRef(null);
  const [selectedValue, setSelectedValue] = useState(value);
  const [searchQuery, setSearchQuery] = useState();



  const handleClickOutside = event => {
    if (dropRef && !dropRef.current?.contains(event.target)) {
      setShowDropDown(false);
    }
  };
  useEffect(() => {
    setSelectedValue(value);

    if (!showDropDown) {
      return;
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => window.removeEventListener('mousedown', handleClickOutside);
  }, [showDropDown, value]);

  const onValueSelect = (e, item) => {
    e.stopPropagation();

    setShowDropDown(false);
    if (onClick) {
      onClick(item);
    }
  };
  const onInputChange = (e) => {
    setSearchQuery(e.target.value);
    // setSelectedValue(e.target.value)
    onChangethis(e.target.value);
  }
  return (
    <div
      ref={dropRef}
      style={{paddingTop:"8px",paddingBottom:"6px",borderRadius:"1px"}}
      onClick={() => (!disabled ? setShowDropDown(true) : null)}
      className="ecs-group"
    >
      <input
        style={{paddingLeft:"10px",backgroundColor:"#fbfbfb",borderRadius: "6px"}}
        placeholder=" "
        readOnly={readOnly}
        disabled={disabled}
        {...props}
        ref={ref}
        onChange={onInputChange}
        value={
          searchQuery
          ?? selectedValue?.name ?? selectedValue ?? ''
        }
      />
      <span className="ecs-highlight"></span>
      <span className="ecs-bar"></span>
      <label
      style={{ paddingLeft: "6px", marginBottom:"10px" ,color:"black" }}
      >
        {label} {icon ? <span className={icon} /> : null}
        {loderUser&&<LoadingOutlined />}
      </label>
      {showDropDown ? (
        <ul className="ecs-dropdown" style={{zIndex:9999}}>
          {data.length>0? data.map((item, index) => (
            <li
              
              key={item.index}
              className={`${value === item ? 'selected-active' : ''}`}
              onClick={e => {
               
                onValueSelect(e, item); setSearchQuery(); onChangethis ? onChangethis(selectedValue.firstName ? selectedValue.firstName : "") : () => { }
              }}
            >
              {item.name ?? `${item.firstName} ${item.lastName}`}
             
            </li>
          )): (!searchQuery||selectedValue)&&<p>Pas de Clients</p>}
        </ul>
      ) : null}
    </div>
  );
};
export default DropDown;

import React, {useState} from 'react';
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Form,
  Button,
  Card,
  CardBody,
  Spinner,
} from 'reactstrap';
import {Input} from 'components/atoms';
import CenterFormApi from 'services/api/CenterFormApi';
import TextArea from 'antd/es/input/TextArea';
import {ConfigProvider} from 'antd';
import InputAnt from 'components/atoms/AntdInput';
import ButtonAntDesign from 'components/atoms/AntdButton';
const pushObj = {
  title: '',
  message: '',
};

const PushNotification = ({ showPushNotification, onHide, user,userName }) => {
  // // console.log(userName?.length);
  // // console.log(user);
  const [push, setPush] = useState({
    ...pushObj,
  });
  const disabledBtn = !push.title || !push.message;
  const [loading, setLoading] = useState(false);

  const onInputChange = (e, target) => {
    setPush({
      ...push,
      [target]: e.target.value,
    });
  };

  const sendPush = async e => {
    e.preventDefault();
    try {

      setLoading(true);
      // if (user != "premium" && user != "physical")
      // {  

        const res = await CenterFormApi.post('/sendPush', {
          user,
          title: push.title,
          message: push.message,
        });
        setPush({
          ...pushObj,
        });
        onHide();
      // }
      // onHide();
    setLoading(false);

    } catch (e) {}
    setLoading(false);
  };
  return (
    <Modal size="lg" isOpen={showPushNotification} toggle={onHide}>
      <ModalHeader
        toggle={onHide}
        close={
          <button className="close" onClick={onHide}>
            &times;
          </button>
        }>
        <span style={{ fontWeight: 700,
              fontStyle: 'italic',
              marginLeft: '10px',}}>Envoyer une notification   {userName?.length>1 ?"à "+userName:"à tous les utilisateurs"} </span>
      </ModalHeader>
      <Form onSubmit={sendPush}>
        <ModalBody>
          <Card style={{padding: '2rem'}} className="card-container">
            <CardBody>
              {/* <label style={{fontSize: '15px', marginBottom: '1px'}}>
                Titre :
              </label>
              <InputAnt
                placeholder="Exp: MagicDeit"
                style={{marginLeft: '0px'}}
                onChange={e => onInputChange(e, 'title')}
                value={push.title}
              /> */}

              {/* <Input
                value={push.title}
                pattern=".*\S+.*"
                onChange={e => onInputChange(e, 'title')}
                // label="Titre"
                type="text"
                required
              /> */}

              <ConfigProvider
                theme={{
                  token: {
                    // Seed Token
                    colorPrimary: 'orange',
                    borderRadius: 5,

                    // Alias Token
                    colorBgContainer: '#bfbfbf0f',
                  },
                }}>
                <label style={{fontSize: '15px', marginBottom: '2px'}}>
                  Message :
                </label>
                <TextArea
                  showCount
                  maxLength={2500}
                  value={push.message}
                  onChange={e => onInputChange(e, 'message')}
                  // value={dataAd.description}
                  // onChange={e =>
                  //   setselectedAd(perv => ({
                  //     ...perv,
                  //     description: e.target.value,
                  //   }))
                  // }
                  placeholder="Votre message ici"
                  style={{
                    // marginBottom: '50px',
                    // marginLeft: '50px',
                    height: 120,
                    resize: 'none',
                  }}
                />
              </ConfigProvider>

              {/* <textarea
                value={push.message}
                className="text-area-style"
                pattern=".*\S+.*"
                onChange={e => onInputChange(e, 'message')}
                placeholder="Un message"
                type="text"
                required
              /> */}
            </CardBody>
          </Card>
        </ModalBody>
        <ModalFooter>
          <ButtonAntDesign
            disabled={!push.message&&!push.title}
            color={'white'}
            type="primary"
            style={{
              // marginTop: "50px",
              // marginBottom:"20px",
              minWidth: '50px',
              color: 'black',
              border: '1px solid black',
              marginLeft: 'auto',
              display: 'block',
            }}
            colorBgContainer={'#ce93d8'}
            onClick={sendPush}
            // className="btn-wide black-button mb-2 mr-2"
            // size="lg"
            text={
              loading ? (
                <span style={{marginRight: '.5rem'}}>
                  <Spinner
                    as="span"
                    variant="warning"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    animation="grow"
                  />{' '}
                </span>
              ) : (
                '' + 'Envoyer'
              )
            }
          />

          {/* <Button
            style={{marginLeft: 'auto', display: 'block'}}
            disabled={loading}
            type="submit"
            className={`btn-wide ecs-save-btn mb-2 mr-2 ${
              disabledBtn ? 'disabledBtn' : ''
            }`}
            size="lg">
            {loading ? (
              <span style={{marginRight: '.5rem'}}>
                <Spinner
                  as="span"
                  variant="warning"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  animation="grow"
                />{' '}
              </span>
            ) : null}
            Envoyer
          </Button> */}
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default PushNotification;

import React, { useContext } from 'react';
import { withRouter } from 'react-router-dom';
import MetisMenu from 'react-metismenu';
import {  CollaboratorNav, MainNav } from './NavItems';
import { AuthContext } from 'services';

const { REACT_APP_ENV } = process.env;

const Nav = ({ location }) => {
  const { user_data } = useContext(AuthContext);

  const [selectedItem, setSelectedItem] = React.useState(null);
  
  const isActive = location.pathname.startsWith("/premiumUsers/");
  
// // console.log("isActive ",location.pathname === '/premiumUsers' || location.pathname === '/premiumUsers/create');
//   const MainNav = [
//     // {
//     //   icon: 'icon-contacts',
//     //   label: 'Clients [dev]',
//     //   //to: '/physicalUsers',
//     //   content: [
//     {
//       icon: 'icon-contacts',
//       label: 'Clients physique',
//       to: '/premiumUsers',
//       // disabled: true,
//       // activeLinkTo:"/create",
//     //  active: location.pathname === '/premiumUsers' || location.pathname === '/premiumUsers/create',
//     },
//     {
//       icon: 'icon-tickets',
//       label: 'Clients semi-physique',
//       to: '/physicalUsers',
//       // disabled: true,
//       // active: isPathActive,
//       // onSelect: () => handleSelect('/physicalUsers'),
//     },
//     {
//       icon: 'icon-contacts',
//       label: 'Clients Digital',
//       to: '/standardUsers',
//       // active: isPathActive,
//     },
//     {
//       icon: 'pe-7s-ticket',
//       label: 'Code promo',
//       to: '/promo-code',
//       // active: isPathActive,
//     },
//   ];


  const handleSelect = (selected) => {
    setSelectedItem(selected);
  };

  
// console.log("log path  ",location.pathname);
  return (
    <div style={{margin:"0px"}}>
      <MetisMenu
        content={
          user_data?.type === 'collaborator'
            ? CollaboratorNav(user_data._id)
            : MainNav
        }
        activeLinkFromLocation
        // activeLinkTo="physicalUsers"
         className="vertical-nav-menu"
        iconNamePrefix=""
        classNameStateIcon="pe-7s-angle-down"
      />
    </div>
  );
};

export default withRouter(Nav);




import * as echarts from 'echarts';
// const startValue = 120;
// const endValue = 80;
// const arrayLength = 50;

// const decreasingArray = [120,110,90,95,80,86,70,80,90,110,91,93,87,86,80,83];

// for (let i = 0; i < arrayLength; i++) {
//   const currentValue = startValue - i;
//   decreasingArray.push(currentValue);
// }
// let base = +new Date(2016, 9, 3);
// let oneDay = 24 * 3600 * 1000;
// let valueBase = [40,50,60,70,80,90,100,110,120,130,140,150,160,170,180,190]
// let valueBase2 = Math.random() * (120 - 80) + 80;
// let data = [];
// let data2 = [];
// for (var i = 1; i < 10; i++) {
//   var now = new Date((base += oneDay));
//   var dayStr = [now.getFullYear(), now.getMonth() + 1, now.getDate()].join('-');
//   // valueBase = Math.round((Math.random() - 0.5) * 20 + valueBase);
//   // valueBase <= 0 && (valueBase = Math.random() * 300);
//   data.push([dayStr, 80]);
//   // valueBase2 = Math.round((Math.random() - 0.5) * 20 + valueBase2);
//   // valueBase2 <= 0 && (valueBase2 = Math.random() * 50);
//   data2.push([dayStr, decreasingArray[i]]);
// }
// // console.log(data);
// // console.log(data2);
// export const optionDate = {
//   title: {
//     left: 'center',
//     text: 'Tootip and dataZoom on Mobile Device'
//   },
//   legend: {
//     top: 'bottom',
//     data: ['Intention']
//   },
//   tooltip: {
//     triggerOn: 'none',
//     position: function (pt) {
//       return [pt[0], 130];
//     }
//   },
//   toolbox: {
//     left: 'center',
//     itemSize: 25,
//     top: 55,
//     feature: {
//       dataZoom: {
//         yAxisIndex: 'none'
//       },
//       restore: {}
//     }
//   },
//   xAxis: {
//     type: 'time',
//     axisPointer: {
//       value: '2016-10-7',
//       snap: true,
//       lineStyle: {
//         color: '#7581BD',
//         width: 2
//       },
//       label: {
//         show: true,
//         formatter: function (params) {
//           return echarts.format.formatTime('yyyy-MM-dd', params.value);
//         },
//         backgroundColor: '#7581BD'
//       },
//       handle: {
//         show: true,
//         color: '#7581BD'
//       }
//     },
//     splitLine: {
//       show: false
//     }
//   },
//   yAxis: {
//     type: 'value',
//     axisTick: {
//       inside: true
//     },
//     splitLine: {
//       show: false
//     },
//     axisLabel: {
//       inside: true,
//       formatter: '{value}\n'
//     },
//     z: 10
//   },
//   grid: {
//     top: 110,
//     left: 15,
//     right: 15,
//     height: 160
//   },
//   dataZoom: [
//     {
//       type: 'inside',
//       throttle: 50
//     }
//   ],
//   series: [
//     {
//       name: 'Fake Data',
//       type: 'line',
//       smooth: true,
//       symbol: 'circle',
//       symbolSize: 5,
//       sampling: 'average',
//       itemStyle: {
//         color: '#0770FF'
//       },
//       stack: 'a',
//       areaStyle: {
//         color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
//           {
//             offset: 0,
//             color: 'rgba(58,77,233,0.8)'
//           },
//           {
//             offset: 1,
//             color: 'rgba(58,77,233,0.3)'
//           }
//         ])
//       },
//       data: data
//     },
//     {
//       name: 'Fake Data',
//       type: 'line',
//       smooth: true,
//       stack: 'a',
//       symbol: 'circle',
//       symbolSize: 5,
//       sampling: 'average',
//       itemStyle: {
//         color: '#F2597F'
//       },
//       areaStyle: {
//         color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
//           {
//             offset: 0,
//             color: 'rgba(213,72,120,0.8)'
//           },
//           {
//             offset: 1,
//             color: 'rgba(213,72,120,0.3)'
//           }
//         ])
//       },
//       data: data2
//     }
//   ]
// };



const generateRandomWeightData = () => {
  const startDate = new Date('2022-01-01');
  const endDate = new Date('2022-12-31');
  const dateArray = [];
  const weightData = [];

  // Generate random data for each day
  for (let currentDate = startDate; currentDate <= endDate; currentDate.setDate(currentDate.getDate() + 1)) {
    const formattedDate = currentDate.toISOString().split('T')[0];
    const actualWeight = Math.random() * 20 + 60; // Random weight between 60 and 80 kg
    const goalWeight = Math.random() * 10 + 70; // Random goal weight between 70 and 80 kg

    dateArray.push(formattedDate);
    weightData.push({ date: formattedDate, actualWeight, goalWeight });
  }

  return { dateArray, weightData };
};

const { dateArray, weightData } = generateRandomWeightData();

// Initialize ECharts

// Define the options for the chart
export const optionsT = {
  title: {
    text: 'Weight Chart',
    left: 'center',
  },
  legend: {
    data: ['Actual Weight', 'Goal Weight'],
    top: 30,
  },
  xAxis: {
    type: 'category',
    data: dateArray,
  },
  yAxis: {
    type: 'value',
    name: 'Weight (kg)',
  },
  series: [
    {
      name: 'Actual Weight',
      type: 'line',
      data: weightData.map(entry => entry.actualWeight),
    },
    {
      name: 'Goal Weight',
      type: 'line',
      data: weightData.map(entry => entry.goalWeight),
    },
  ],
};
